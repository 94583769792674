























import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import { computed, ref, watchEffect } from '@vue/composition-api';
import { useUserGetters, useUserActions, useDbGetters } from '@/store';

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);
interface FileType {
  source: string | null | undefined;
  options: {
    type: string;
  };
}
export default {
  components: {
    FilePond
  },
  props: {
    size: {
      required: true,
      type: Number
    },
    image: {
      required: false,
      type: String,
      default: ''
    },
    programId: {
      required: true,
      type: String,
      default: ''
    },
    imageType: {
      required: true,
      type: String,
      default: ''
    }
  },
  setup(props: any, ctx: any) {
    // init data
    const { getUser } = useUserGetters(['getUser']);
    const { uploadImage } = useUserActions(['uploadImage']);
    const user = getUser.value;
    const myFiles = computed(() => {
      const files: FileType[] = [];
      if (props?.image)
        files.push({
          source: props?.image,
          options: {
            type: 'local'
          }
        });
      return files;
    });

    const server = ref({
      process(fieldName, file, metadata, load, error, progress, abort) {
        const reader = new FileReader();
        reader.onload = async (fileEvent: Event) => {
          const base64Encoded = (fileEvent.target as any)?.result;
          const data = await uploadImage({
            programID: props?.programId?.toString(),
            image: base64Encoded,
            imageType: props?.imageType
          });
          const bodyData = JSON.parse(data?.body);
          if (bodyData) {
            ctx.emit('uploadeImage', bodyData?.data?.image);
          } else {
            ctx.emit('removeImage', 'removeImage');
          }
          // if (data) {
          //   const imageUrl = JSON.parse(data?.body?.data?.image);
          //   if (imageUrl) {
          //     console.log(imageUrl);
          //   }
          // }
          //   if (data) {
          //     load(data.medium);
          //   }
        };
        reader.readAsDataURL(file);
        return {
          abort: () => {
            abort();
          }
        };
      },
      load(source, load, error, progress, abort) {
        // Should request a file object from the server here
        const myRequest = new Request(source);
        fetch(myRequest)
          .then(response => {
            response.blob().then(myBlob => {
              const blob = myBlob.slice(0, myBlob.size, 'image/jpeg');
              load(blob);
            });
          })
          .catch(err => error('Something went wrong'));
        return {
          abort: () => {
            abort();
          }
        };
      },
      async remove(source, load) {
        // delete profile images from user doc
        // await removeProfileImage();
        ctx.emit('removeImage', 'removeImage');
        load();
      }
    });
    const src = ref('');
    watchEffect(() => {
      if (props?.image) src.value = props?.image;
    });
    return {
      myFiles,
      server,
      user,
      src
    };
  }
};
